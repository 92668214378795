import React from "react"
import { graphql } from "gatsby"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"
import Carousel, { CarouselImages } from "Components/Carousel"

interface HexagonCloneProps {
  data: CarouselImages
}

const HexagonClone: React.FC<HexagonCloneProps & GatsbyProps> = ({
  data: { smallestImages, smallImages, mediumImages, largeImages },
  location,
}) => (
  <StandardLayout location={location}>
    <SEO title="Twitter Heatmap" />
    <h1>Twitter Heatmap</h1>
    <p>
      My first processing application used twitters API to search for recent
      tweets that contained a keyword, then using that information the
      application would create a heat map overlay for Europe or the United
      States.
    </p>
    <p>
      To have a look at it yourself{" "}
      <a href="https://github.com/LiamMyles/Twitter-Heatmap-2014">
        checkout this app&apos;s code on GitHub
      </a>
    </p>
    <Carousel
      smallestImages={smallestImages}
      smallImages={smallImages}
      mediumImages={mediumImages}
      largeImages={largeImages}
      altTexts={[
        "Shield with twin swords",
        "Twin Swords on Altar",
        "Swords on alter with shield",
      ]}
    />
  </StandardLayout>
)

export default HexagonClone

export const query = graphql`
  query {
    smallestImages: allFile(
      filter: { relativePath: { glob: "processing/twitter-geo-heatmap*" } }
    ) {
      edges {
        node {
          ...CarouselSmallestImage
        }
      }
    }
    smallImages: allFile(
      filter: { relativePath: { glob: "processing/twitter-geo-heatmap*" } }
    ) {
      edges {
        node {
          ...CarouselSmallImage
        }
      }
    }
    mediumImages: allFile(
      filter: { relativePath: { glob: "processing/twitter-geo-heatmap*" } }
    ) {
      edges {
        node {
          ...CarouselMediumImage
        }
      }
    }
    largeImages: allFile(
      filter: { relativePath: { glob: "processing/twitter-geo-heatmap*" } }
    ) {
      edges {
        node {
          ...CarouselLargeImage
        }
      }
    }
  }
`
